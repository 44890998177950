
.main {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 344px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .news-item {
        background: #FFF;
        border-radius: 8px;
        padding: 0 20px;
        .news-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 47px;
            width: 100%;
            border-bottom: 1px solid #F2F2F4;
            .header-ico {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .img {
                    margin-right: 6px;
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;

                    }
                }
                .title {
                    color: #242632;
                }
            }
            .header-tag {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2px solid #685EF5;
                color: #666666;
            }
        }
        .news-list {
            .item {
                cursor: pointer;
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .index {
                    margin-right: 10px;
                    color: #929399;
                    &.index_0 {
                        color: #FF2C55;
                    }
                    &.index_1 {
                        color: #FF851D;
                    }
                    &.index_2 {
                        color: #FF748F;
                    }
                }
                .title {
                    flex: 1;
                    width: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .number {
                    color: #999999;
                    //width: 70px;
                }
            }
        }
    }
}

